import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, CardBox, Loading, Title } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import parse from "html-react-parser";

const OurProducts = () => {
  const { data } = useFETCH(`info-text/our_products`);
  const {
    data: datas,
    isLoading,
    deleteItem,
  } = useFETCH(`slider-image?type=OUR_PRODUCTS`, `slider-image`);

  return (
    <div className="py-5">
      <Title title="منتجاتنا" />
      {isLoading ? <Loading /> : ""}
      <Col lg={9} className="mx-auto">
        <CardBox deleted edit={`/our_products/our_products`}>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
            <div>
              {parse(
                data?.data.data.info_value?.ar
                  ? data?.data.data.info_value?.ar
                  : ""
              )}
            </div>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
            <div>
              {parse(
                data?.data.data.info_value?.en
                  ? data?.data.data.info_value?.en
                  : ""
              )}
            </div>
          </div>
        </CardBox>
      </Col>
      <div className="border-2 border-Main my-8 w-4/5 mx-auto" />
      <Row>
        {datas?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox showEdit="true" deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[200px] rounded-3xl"
              />
            </CardBox>
          </Col>
        ))}
      </Row>
      <div className="flex justify-end">
        <ButtonRed
          name="أضف صورة"
          className="w-fit ml-auto px-5 py-2"
          link="/our_products/add"
        />
      </div>
    </div>
  );
};

export default OurProducts;
