import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import {
  Add,
  CardBox,
  Data,
  DataAr,
  Loading,
  Title,
  TopCompoundFilter,
} from "../../components";
import parse from "html-react-parser";

function Launches() {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `coming-soon-items?page=${filter.get("page") ? filter.get("page") : 1}${
      filter.get("trending") ? "&trending=" + filter.get("trending") : ""
    }`,
    "coming-soon-items"
  );
  const { data: dataLun } = useFETCH(`info-text/description_launching`);
  return (
    <div>
      <Title title="ينطلق قريباً" />
      <Row>
        <Col lg={9} className="mx-auto mb-5">
          <CardBox deleted edit={`/launches/description/description_launching`}>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  dataLun?.data.data.info_value?.ar
                    ? dataLun?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  dataLun?.data.data.info_value?.en
                    ? dataLun?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
      </Row>
      <TopCompoundFilter />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col md={4} xs={6}>
              <CardBox
                deleteClick={() => deleteItem(e)}
                edit={`/launches/${e.id}`}
              >
                <img
                  src={fileUrl + e.image}
                  alt=""
                  className="w-full h-[200px]"
                />
                <Data name="الاسم بالعربي" text={e.name?.ar} />
                <Data name="الاسم بالإنكليزي" text={e.name?.en} />
                <Data name="السعر يبدأ من" text={e.price_start_from} />
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
      <Add link="/launches/add" />
    </div>
  );
}
export default Launches;
