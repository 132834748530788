import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, CardBox, Title } from "../../components";
import parse from "html-react-parser";

const Design = () => {
  const { data, isLoading, deleteItem } = useFETCH(
    `slider-image?type=DESIGN`,
    `slider-image`
  );
  const { data: dataDesign } = useFETCH(`info-text/design`);
  return (
    <div className="py-5">
      <Title title="التصميم" />
      <Col lg={9} className="mx-auto">
        <CardBox deleted edit={`/design/design`}>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
            <div>
              {parse(
                dataDesign?.data.data.info_value?.ar
                  ? dataDesign?.data.data.info_value?.ar
                  : ""
              )}
            </div>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
            <div>
              {parse(
                dataDesign?.data.data.info_value?.en
                  ? dataDesign?.data.data.info_value?.en
                  : ""
              )}
            </div>
          </div>
        </CardBox>
      </Col>
      {isLoading ? <isLoading /> : ""}
      <div className="border-2 border-Main my-8 w-4/5 mx-auto" />
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox showEdit="true" deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[200px] rounded-3xl"
              />
            </CardBox>
          </Col>
        ))}
      </Row>
      <div className="flex justify-end">
        <ButtonRed
          name="أضف صورة"
          className="w-fit ml-auto px-5 py-2"
          link="/design/add"
        />
      </div>
    </div>
  );
};

export default Design;
