import { Col, Row } from "../../Tools/Grid-system";
import { Add, CardBox, Loading, Title } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
const Attached = () => {
  const { data, isLoading, deleteItem } = useFETCH(`attacheds`, "attacheds");
  return (
    <>
      <Title title="مرافق" />
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox
              deleteClick={() => deleteItem(e)}
              edit={`/attached/${e.id}`}
            >
              <div className="flex items-center justify-between mx-3">
                <img
                  src={fileUrl + e.icon}
                  alt=""
                  className="w-14 h-14 rounded-full p-2 border border-blue-400"
                />
                <div>
                  <h2 className="text-center text-xl font-semibold">
                    {e.name?.en}
                  </h2>
                  <h2 className="text-center text-xl font-semibold">
                    {e.name?.ar}
                  </h2>
                </div>
              </div>
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link="/attached/add" />
    </>
  );
};

export default Attached;
