import { Col } from "../../Tools/Grid-system";
import { CardBox, Loading, Title } from "../../components";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import parse from "html-react-parser";

const AboutUs = () => {
  const { data, isLoading } = useFETCH(`info-text/about_us`);
  return (
    <div className="py-5">
      {isLoading ? <Loading /> : ""}
      <Title title="معلومات عنا" />
      <Col lg={9} className="mx-auto">
        <CardBox deleted edit={`/about-us/about_us`}>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
            <div>
              {parse(
                data?.data.data.info_value?.ar
                  ? data?.data.data.info_value?.ar
                  : ""
              )}
            </div>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
            <div>
              {parse(
                data?.data.data.info_value?.en
                  ? data?.data.data.info_value?.en
                  : ""
              )}
            </div>
          </div>
        </CardBox>
      </Col>
    </div>
  );
};

export default AboutUs;
