import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { AddImage, Back, ButtonRed, Input, Loading } from "../../components";
import { useEffect } from "react";

const EditArea = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    setViewFile,
    handleSubmit,
    setFormData,
    viewFile,
    formData,
    loading,
    error,
  } = usePOST({ trending: 0 });
  const { data } = useFETCH(id === "add" ? "" : `areas/${id}?local=none`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "areas" : `areas/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        en_name: dataOld?.name?.en,
        ar_name: dataOld?.name?.ar,
        trending: dataOld?.trending,
      });
  }, [dataOld]);
  return (
    <div>
      <Row justify={"center"}>
        <Col md={9}>
          <Row
            justify="center"
            className="border-2 border-Secondary p-8 rounded-xl"
          >
            <Input
              name="ar_name"
              value={formData?.ar_name}
              onChange={handleChangeInput}
              title="الاسم بالعربي"
            />
            <Input
              name="en_name"
              value={formData?.en_name}
              onChange={handleChangeInput}
              title="الاسم بالإنكليزي"
              className="text-end"
            />
            <Col>
              <AddImage
                name="image"
                newImage={viewFile}
                oldImage={dataOld?.image && fileUrl + dataOld?.image}
                onChange={handleChangeInput}
                clickDeleteImage={() => {
                  setViewFile("");
                  setFormData({ ...formData, images: "" });
                }}
              />
            </Col>
            <div className="flex items-center gap-3 my-4 text-lg font-semibold">
              <input
                type="checkbox"
                name="trending"
                checked={formData.trending}
                onChange={handleChangeInput}
                className="w-6 h-6"
              />
              <p>رائج</p>
            </div>
            {loading ? <Loading /> : ""}
            <div className="text-red-500 font-semibold">{error}</div>
            <Col col={6}>
              <ButtonRed
                onClick={handleSubmitMain}
                name="أضف"
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default EditArea;
