import { BiSave } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, Loading } from "../../components";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineMail, MdWorkOutline } from "react-icons/md";
import { useEffect, useState } from "react";
import { useContactUs, useFETCH } from "../../Tools/APIs/useMyAPI";

const UpdateContact = () => {
  const [formDataContact, setFormDataContact] = useState({});
  const { loading, updateContactUs } = useContactUs("contact-us");
  const { data } = useFETCH(`contact-us?local=en`);
  let dataOld = data?.data.data;
  useEffect(() => {
    setFormDataContact({
      instagram: dataOld?.instagram,
      facebook: dataOld?.facebook,
      whatsapp: dataOld?.whatsapp,
      email: dataOld?.email,
      phone_number: dataOld?.phone_number,
      working_hours: dataOld?.working_hours,
    });
  }, [dataOld]);
  return (
    <>
      <Row justify="center" gap={5}>
        {loading ? <Loading /> : ""}
        <Col
          md={5}
          className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <FaPhone size={50} className="" color="#FEDADA" />
          <input
            type="text"
            name="phone_number"
            value={formDataContact?.phone_number}
            onChange={(e) =>
              setFormDataContact({
                ...formDataContact,
                phone_number: e.target.value,
              })
            }
            placeholder="phone number"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              onClick={() =>
                updateContactUs("phone_number", formDataContact.phone_number)
              }
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className="border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <MdOutlineMail size={60} className="" color="#9F7CC6" />
          <input
            type="text"
            value={formDataContact?.email}
            onChange={(e) =>
              setFormDataContact({
                ...formDataContact,
                email: e.target.value,
              })
            }
            placeholder="e-mail"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              onClick={() => updateContactUs("email", formDataContact.email)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>

        <Col
          md={5}
          className="border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <MdWorkOutline size={60} className="" color="#5e2aC6" />
          <input
            type="text"
            value={formDataContact?.working_hours}
            onChange={(e) =>
              setFormDataContact({
                ...formDataContact,
                working_hours: e.target.value,
              })
            }
            placeholder="working hours"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              onClick={() =>
                updateContactUs("working_hours", formDataContact.working_hours)
              }
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <FaInstagram size={60} color="#CB3878" />
          <input
            type="text"
            name="instagram"
            value={formDataContact?.instagram}
            onChange={(e) =>
              setFormDataContact({
                ...formDataContact,
                instagram: e.target.value,
              })
            }
            placeholder="https://url....instagram"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              onClick={() =>
                updateContactUs("instagram", formDataContact.instagram)
              }
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className="border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <FaFacebook size={60} color="#3B5998" />
          <input
            type="text"
            value={formDataContact?.facebook}
            onChange={(e) =>
              setFormDataContact({
                ...formDataContact,
                facebook: e.target.value,
              })
            }
            placeholder="https://url....facebook"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              onClick={() =>
                updateContactUs("facebook", formDataContact.facebook)
              }
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>

        <Col
          md={5}
          className=" border-4 border-gray-500 rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <BsWhatsapp size={60} color="#1DCD2A" />
          <input
            type="text"
            value={formDataContact?.whatsapp}
            onChange={(e) =>
              setFormDataContact({
                ...formDataContact,
                whatsapp: e.target.value,
              })
            }
            placeholder="https://url...."
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className="">
            <BiSave
              onClick={() =>
                updateContactUs("whatsapp", formDataContact.whatsapp)
              }
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default UpdateContact;
