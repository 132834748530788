import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { ButtonRed, CardBox, Title } from "../../components";
const ImageSlider = () => {
  const { data, isLoading, deleteItem } = useFETCH(
    `slider-image?type=HOME`,
    `slider-image`
  );

  return (
    <>
      <Title title="صور السلايدر" />
      {isLoading ? <isLoading /> : ""}
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox showEdit="true" deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[200px] rounded-3xl"
              />
            </CardBox>
          </Col>
        ))}
      </Row>
      <div className="flex justify-end">
        <ButtonRed
          name="أضف صورة"
          className="w-fit ml-auto px-5 py-2"
          link="/home/add"
        />
      </div>
    </>
  );
};

export default ImageSlider;
