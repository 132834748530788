import React, { useState } from "react";
import { Col, Row } from "../../Tools/Grid-system";
import { Loading, Title } from "../../components";
import { usePOST } from "../../Tools/APIs/useMyAPI";

const ChangePassword = () => {
  const [biShow, setBiShow] = useState(false);
  const { handleSubmit, error, loading, handleChangeInput } = usePOST({});
  const handleSubmitSave = () => {
    handleSubmit("change-password", true);
  };
  return (
    <div>
      <Title title="Change Password" />
      <Row justify="center">
        <Col md={7}>
          <div className="border-2 border-Main rounded-2xl  py-10 md:px-6">
            <input
              name="old_password"
              type={biShow ? "text" : "password"}
              className="border border-Main py-4 rounded-2xl my-3"
              placeholder="Old Password"
              onChange={handleChangeInput}
            />
            <input
              name="password"
              type={biShow ? "text" : "password"}
              className="border border-Main py-4 rounded-2xl my-3"
              placeholder="New Password"
              onChange={handleChangeInput}
            />
            <input
              name="password_confirmation"
              type={biShow ? "text" : "password"}
              className="border border-Main py-4 rounded-2xl my-3"
              placeholder="Retype new Password"
              onChange={handleChangeInput}
            />
            <div className="text-end text-l text-Brown flex items-center justify-start space-x-2">
              <input
                type="checkbox"
                className="relative w-5 h-5"
                onChange={(e) => setBiShow(e.target.checked)}
              />
              <span>Show Password</span>
            </div>
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <div
              onClick={handleSubmitSave}
              className="cursor-pointer w-full border border-Main py-3 mt-10 rounded-2xl text-white font-semibold text-xl  text-center bg-gradient-to-l to-Main from-Main"
            >
              Save Change
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
