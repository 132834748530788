import Input from "../../components/Input/Input";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { useEffect } from "react";
import { Loading } from "../../components";
import { Container } from "../../Tools/Grid-system";
function ForgetPassword() {
  const { handleSubmit, error, loading, handleChangeInput, formData } = usePOST(
    {}
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("send/verification-code", "/verification-code");
  };
  useEffect(() => {
    sessionStorage.setItem("email", formData?.email);
  }, [formData]);
  return (
    <Container>
      <div className="fixed w-full h-full bg-white top-0 left-0 z-50 flex items-center justify-center text-center">
        <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Main p-10 rounded-2xl">
          <h1 className="text-2xl font-semibold max-xsm:text-lg">
            Forger password
          </h1>
          <Input
            name="email"
            onChange={handleChangeInput}
            title="Enter your user name or phone"
          />
          {loading ? <Loading /> : ""}
          <div className="text-red-600">{error}</div>
          <div className="w-fit mx-auto">
            <button
              className="border px-10 border-Main hover:text-white text-Main hover:bg-Main bg-white font-semibold p-3 rounded-xl"
              type="submit"
              onClick={handleSubmitMain}
            >
              continued
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default ForgetPassword;
