import React, { useEffect } from "react";
import {
  Back,
  ButtonRed,
  Input,
  Loading,
  PasswordInput,
  Title,
} from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";

const AddUser = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(id === "add" ? "" : `users/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "users" : `users/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
        email: dataOld?.email,
        password: dataOld?.password,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Main rounded-2xl p-10 ">
            <Title title="أضف مستخدم" className="mb-14 mt-5" />
            <Input
              name="name"
              value={formData?.name}
              onChange={handleChangeInput}
              title="اسم المستخدم"
            />
            <Input
              type="email"
              name="email"
              value={formData?.email}
              onChange={handleChangeInput}
              title="الإيميل (إختياري)"
            />
            <PasswordInput
              name="password"
              value={formData?.password}
              onChange={handleChangeInput}
            />
            {loading ? <Loading /> : ""}
            <div className="text-red-500 font-semibold">{error}</div>
            <Col col={6} className="mx-auto mt-5">
              <ButtonRed
                onClick={handleSubmitMain}
                name="أضف"
                className="h-full px-4 py-3"
              />
            </Col>
          </div>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default AddUser;
