import PrintHTML from "../PrintHTML/PrintHTML";

function DataDes({ name, text, className }) {
  return (
    <div className={`font-bold text-Main mt-3 ${className}`}>
      <span className=""> {name} </span>
      <span className="font-normal text-black ">
        {text && <PrintHTML text={text} />}
      </span>
    </div>
  );
}
export default DataDes;
