import { useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { CiSearch } from "react-icons/ci";

function CoinFilter() {
  const { filter, setFilter } = useFilter({});
  return (
    <div>
      <Row>
        <Col md={4} xs={6}>
          <div className="border-2 border-Main flex items-center justify-between space-x-2 p-2 rounded-xl">
            <input
              type="search"
              placeholder="إبحث"
              onChange={(e) =>
                setFilter({
                  page: filter.get("page") ? filter.get("page") : 1,
                  is_active: filter.get("is_active") ? filter.get("is_active") : 1,
                  search: e.target.value,
                })
              }
              value={filter.get("search")}
            />
            <span>
              <CiSearch size={25} className="h-full" />
            </span>
          </div>
        </Col>
      </Row>
      <Row className="my-5">
        <Col md={4} sm={6}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${filter.get("is_active") === "1" ? "!bg-Main text-white" : ""} `}
            onClick={() =>
              setFilter({
                is_active: "1",
                // page: filter.get("page") ? filter.get("page") : "",
              })
            }
          >
            متاح
          </div>
        </Col>
        <Col md={4} sm={6}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${filter.get("is_active") === "0" ? "!bg-Main text-white" : ""} `}
            onClick={() =>
              setFilter({
                is_active: "0",
                // page: filter.get("page") ? filter.get("page") : "",
              })
            }
          >
            غير متاح
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default CoinFilter;
