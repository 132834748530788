import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/Logo.png";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { name: "الصفحة الرئيسية", link: "/Home" },
    { name: "المستخدمين", link: "/Users" },
    { name: "المناطق", link: "/area" },
    { name: "المرفقات", link: "/attached" },
    { name: "نوع العقار", link: "/property-type" },
    { name: "المُؤسسون", link: "/founders" },
    { name: "الجديد", link: "/news" },
    { name: "تواصل معنا", link: "/Contact-Us" },
    { name: "قادم قريباً", link: "/launches" },
    { name: "الرسائل", link: "/Message" },
    { name: "العملات", link: "/coins" },
    { name: "المنتدى", link: "/blogs" },
    { name: "المجمع", link: "/compounds" },
    { name: "العقارات", link: "/properties" },
    { name: "التصميم", link: "/design" },
    // { name: "السيرة", link: "/careers" },
    { name: "معلومات عنا", link: "/about-us" },
    { name: "كيف تعمل", link: "/how-it-works" },
    { name: "منتجاتنا", link: "/our_products" },
    { name: "شروطنا", link: "/terms-of-use" },
    { name: "نصوص المشروع", link: "/text-info" },
    { name: "تغيير كلمة السر", link: "/Change-Password" },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "!w-[360px] max-lg:!w-[300px] max-sm:!w-[250px]"
            : " w-[0px]"
        } ${
          openMenu
            ? "!max-md:translate-x-0 !max-md:w-3/4 -right-0"
            : "!max-md:translate-x-full"
        }  bg-Main sidebar h-[100vh] sideBar max-md:h-[100%] sticky max-md:fixed right-0 top-0 transition-all overflow-y-scroll  py-10 z-40 max-md:z-50`}
      >
        <div className="px-3">
          <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
            <li className="">
              <img
                src={logo}
                alt=""
                className="w-[200px] bg-white rounded-[20px] mx-auto"
              />
            </li>
            {sidebar.map((e) => (
              <li className="">
                <NavLink
                  to={e.link}
                  className="text-xl py-2 block rounded-3xl hover:bg-white hover:text-[#2F2C8F]"
                >
                  {e.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-md:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-50`}
      />
    </>
  );
}

export default SideBar;
