import { useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";

function PropertyTypeFilter() {
  const { filter, setFilter } = useFilter({});
  return (
    <div>
      <Row className="my-5">
        <Col md={4} xs={6}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${filter.get("type") === "" ? "!bg-Main text-white" : ""} `}
            onClick={() =>
              setFilter({
                type: "",
              })
            }
          >
            الكل
          </div>
        </Col>
        <Col md={4} xs={6}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${
             filter.get("type") === "RESIDENTIAL" ? "!bg-Main text-white" : ""
           } `}
            onClick={() =>
              setFilter({
                type: "RESIDENTIAL",
              })
            }
          >
            سكني
          </div>
        </Col>
        <Col md={4} xs={6}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${
             filter.get("type") === "COMMERCIAL" ? "!bg-Main text-white" : ""
           } `}
            onClick={() =>
              setFilter({
                type: "COMMERCIAL",
              })
            }
          >
            تجاري
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default PropertyTypeFilter;
