import { Col, Row } from "../../../Tools/Grid-system";
import { ButtonRed, CardBox, Title } from "../../../components";

function JobOpenings() {
  return (
    <div>
      <Title title="Job Openings" />
      <Row>
        <Col xs={6}>
          <CardBox edit={`/Careers/JobOpeningsEdit`}>
            <h2 className="text-center text-2xl font-semibold text-red-600 mb-4">
              Job Openings
            </h2>
            <div className=" flex text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Main">
                Job Title:
              </span>
              <div>
                <p> Principle Product Owner</p>
                <p>Posted Today</p>
              </div>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Main">
                Location:
              </span>
              <span> Maadi, Cairo Governorate, Egypt</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Main">
                Work type:
              </span>
              <span> Full Time</span>
            </div>
          </CardBox>
        </Col>
      </Row>
      <ButtonRed
        name="Add New jop"
        className="w-fit ml-auto px-5 py-2"
        link="/Careers/JobOpeningsEdit"
      />
    </div>
  );
}
export default JobOpenings;
