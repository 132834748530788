import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { CardBox, Title } from "../../components";
import parse from "html-react-parser";
function HowItWorks() {
  const { data } = useFETCH(`info-text/description_buyer`);
  const { data: dataSel } = useFETCH(`info-text/description_seller`);

  return (
    <div>
      <Title title="كيف تعمل" />
      <Row className="justify-center">
        <Col lg={9}>
          <CardBox deleted edit="/how-it-works/buyer/description_buyer">
            <h2 className="text-xl font-semibold text-red-600 text-center">
              وصف البائع
            </h2>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  data?.data.data.info_value?.ar
                    ? data?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  data?.data.data.info_value?.en
                    ? data?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
        <Col lg={9}>
          <CardBox deleted edit="/how-it-works/seller/description_seller">
            <h2 className="text-xl font-semibold text-red-600 text-center">
              وصف المشتري
            </h2>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  dataSel?.data.data.info_value?.ar
                    ? dataSel?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  dataSel?.data.data.info_value?.en
                    ? dataSel?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
      </Row>
    </div>
  );
}
export default HowItWorks;
