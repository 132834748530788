import { Col, Row } from "../../Tools/Grid-system";
import { Add, CardBox, Data, DataDes, Loading, Title } from "../../components";
import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
const Properties = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `properties?page=${filter.get("page") ? filter.get("page") : 1}`,
    "properties"
  );
  return (
    <>
      <Title title="العقارات" />
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data.data?.map((e) => (
          <Col md={6} key={e.id}>
            <CardBox
              deleteClick={() => deleteItem(e)}
              edit={`/properties/${e.id}`}
              // show={`/properties/view/${e.id}`}
            >
              <img
                src={fileUrl + e.images[0]?.file}
                alt=""
                className="w-full h-[200px] rounded-2xl p-2"
              />
              <Data name="الاسم بالإنكليزي" text={e.name?.en} />
              <Data name="الاسم بالعربي" text={e.name?.ar} />
              <Data name="الموقع بالإنكليزي" text={e.address?.en} />
              <Data name="الموقع بالعربي" text={e.address?.ar} />
              <DataDes name="الوصف بالعربي" text={e.description?.ar} />
              <DataDes name="الوصف بالإنكليزي" text={e.description?.en} />
              <Data name="عمليات الدفع بالإنكليزي" text={e.payment_plans?.en} />
              <Data name="عمليات الدفع بالعربي" text={e.payment_plans?.ar} />
              <Data name="رقم الهاتف" text={e.phone_number} />
              <Data name="رقم الواتس أب" text={e.whatsapp} />
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link={`/properties/add`} />
    </>
  );
};

export default Properties;
