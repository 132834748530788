import { Col, Row } from "../../Tools/Grid-system";
import { Add, CardBox, Data, DataDes, Loading, Title } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs/useMyAPI";
const Founders = ({ name, api, rout }) => {
  const { data, isLoading, deleteItem } = useFETCH(
    `founders?type=${api}`,
    "founders"
  );
  return (
    <>
      <Title title={name} />
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data?.map((e) => (
          <Col md={4} xs={6} key={e.id}>
            <CardBox
              deleteClick={() => deleteItem(e)}
              edit={`/${rout}/${e.id}`}
            >
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[200px] rounded-2xl p-2"
              />
              <Data name="الاسم بالعربي" text={e.name?.ar} />
              <Data name="الاسم بالإنكليزي" text={e.name?.en} />
              <DataDes
                className="flex-col"
                name="الوصف بالعربي"
                text={e.description?.ar.substring(0, 80)}
              />
              <DataDes
                className="flex-col"
                name="الوصف بالإنكليزي"
                text={e.description?.en.substring(0, 80)}
              />
              <Data name="type" text={e.type} />
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link={`/${rout}/add`} />
    </>
  );
};

export default Founders;
