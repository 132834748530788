import { useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";

function TopCompoundFilter() {
  const { filter, setFilter } = useFilter({});
  return (
    <div>
      <Row className="my-5">
        <Col md={4}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${filter.get("trending") === "" ? "!bg-Main text-white" : ""} `}
            onClick={() =>
              setFilter({
                trending: "",
              })
            }
          >
            الكل
          </div>
        </Col>
        <Col md={4}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${filter.get("trending") === "1" ? "!bg-Main text-white" : ""} `}
            onClick={() =>
              setFilter({
                trending: "1",
              })
            }
          >
            الرائج
          </div>
        </Col>
        <Col md={4}>
          <div
            className={`border-2 border-Main bg-white text-Main rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
           ${filter.get("trending") === "0" ? "!bg-Main text-white" : ""} `}
            onClick={() =>
              setFilter({
                trending: "0",
              })
            }
          >
            الغير رائج
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default TopCompoundFilter;
