import { Col, Row } from "../../Tools/Grid-system";
import {
  Add,
  CardBox,
  Data,
  Loading,
  PropertyTypeFilter,
  Title,
} from "../../components";
import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
const PropertyType = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `property-type?page=${filter.get("page") ? filter.get("page") : 1}${
      filter.get("type") ? "&type=" + filter.get("type") : ""
    }`,
    "property-type"
  );
  return (
    <>
      <Title title="نوع العقار" />
      <PropertyTypeFilter />
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox
              deleteClick={() => deleteItem(e)}
              edit={`/property-type/${e.id}`}
            >
                <img
                  src={fileUrl + e.icon}
                  alt=""
                  className="w-14 h-14 mx-auto border shadow rounded-full p-2"
                />
                <Data name="الاسم بالعربي" text={e.name?.ar} />
                <Data name="الاسم بالإنكليزي" text={e.name?.en} />
                <Data name="النوع" text={e.type} />
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link="/property-type/add" />
    </>
  );
};

export default PropertyType;
