import { Link } from "react-router-dom";
import { Col, Row } from "../../Tools/Grid-system";
import {
  Add,
  CardBox,
  Loading,
  Title,
  TopCompoundFilter,
} from "../../components";
import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import Pagination from "../../Tools/Pagination";
const Areas = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `areas?page=${filter.get("page") ? filter.get("page") : 1}${
      filter.get("trending") ? "&trending=" + filter.get("trending") : ""
    }`,
    "areas"
  );
  return (
    <>
      <Title title="المناطق" />
      <TopCompoundFilter />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col lg={3} md={4} xs={6} key={e.id}>
              <CardBox deleteClick={() => deleteItem(e)} edit={`/area/${e.id}`}>
                <Link to={`/area/${e.id}`}>
                  <img
                    src={fileUrl + e.image}
                    alt=""
                    className="w-full h-[150px] rounded-3xl"
                  />
                  <h2 className="text-center text-xl text-Main font-semibold">
                    {e.name?.en}
                  </h2>
                  <h2 className="text-center text-xl text-Main font-semibold">
                    {e.name?.ar}
                  </h2>
                </Link>
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
      <Add link="/area/add" />
    </>
  );
};

export default Areas;
