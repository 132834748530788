import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { Add, CardBox, Data, DataDes, Loading, PrintHTML, Title } from "../../components";

function Blogs() {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `blogs?page=${filter.get("page") ? filter.get("page") : 1}`,
    "blogs"
  );
  console.log(data);
  return (
    <div>
      <Title title="المنتدى" />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col lg={4} md={6}>
              <CardBox
                deleteClick={() => deleteItem(e)}
                edit={`/blogs/${e.id}`}
              >
                <img
                  src={fileUrl + e.images[0]?.image}
                  alt=""
                  className="w-full h-[200px]"
                />
                <Data className="" name="الاسم بالعربي" text={e.name?.ar} />
                <Data className="" name="الاسم بالإنكليزي" text={e.name?.en} />
                <DataDes
                  name="النص القصير بالعربي"
                  text={e.small_text?.ar}
                />
                <DataDes
                  name="النص القصير بالإنكليزي"
                  text={e.small_text?.en}
                />
                <DataDes
                  name="النص الطويل بالعربي"
                  text={e.large_text?.ar}
                />
                <DataDes
                  name="النص الطويل بالإنكليزي"
                  text={e.large_text?.en}
                />
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
      <Add link={`/blogs/add`} />
    </div>
  );
}
export default Blogs;
