import Input from "../../components/Input/Input";
import { useEffect, useState } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Container } from "../../Tools/Grid-system";
import { Loading } from "../../components";
function SetPassword() {
  const [password, setPassword] = useState(false);
  const {
    setFormData,
    handleSubmit,
    error,
    loading,
    handleChangeInput,
    formData,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("password/reset", "/login");
  };
  useEffect(() => {
    setFormData({
      ...formData,
      verification_code: sessionStorage.getItem("code"),
      email: sessionStorage.getItem("email"),
    });
  }, []);
  return (
    <Container>
      <div className="fixed w-full h-full bg-white top-0 left-0 z-50 flex items-center justify-center text-center">
        <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Main p-10 rounded-2xl">
          <h1 className="text-2xl font-semibold max-xsm:text-lg">
            New password
          </h1>
          <Input
            type={!password ? "password" : "text"}
            title="SetPassword"
            name="password"
            onChange={handleChangeInput}
          />
          <Input
            type={!password ? "password" : "text"}
            title="Confirm password"
            name="password_confirmation"
            onChange={handleChangeInput}
          />
          <div className="text-lg mt-5 flex items-center justify-center">
            <input
              type="checkbox"
              onChange={(e) => setPassword(e.target.checked)}
              name="checkbox"
              className="mx-3 w-fit"
            />
            <span>show password</span>
          </div>
          {loading ? <Loading /> : ""}
          <div className="text-red-600">{error}</div>
          <div className="w-fit mx-auto">
            <button
              className="border px-10 max-xsm:px-2 border-Main hover:text-white text-Main hover:bg-Main bg-white font-semibold p-3 rounded-xl"
              type="submit"
              onClick={handleSubmitMain}
            >
              Confirm password
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default SetPassword;
