import { Col, Row } from "../../../Tools/Grid-system";
import { ButtonRed, CardBox } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";

function OurBenefits() {
  return (
    <div>
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/careers/OurBenefitsEdit`}>
            <div className="flex justify-start items-center text-xl gap-2">
              <div className="flex justify-center items-center w-[150px] h-[150px] bg-gradient-to-l from-[#062371] to-[#E00201] rounded-full">
                <img src={image} alt="" className="rounded-full w-3/4 h-3/4" />
              </div>
              Alhamra
            </div>
          </CardBox>
        </Col>
        <ButtonRed
          name="Add benefit"
          className="w-fit ml-auto px-5 py-2 mb-3"
          link="/careers/OurBenefitsEdit"
        />
      </Row>
    </div>
  );
}
export default OurBenefits;
