import JoditEditor from "jodit-react";
import { Back, ButtonRed, Loading } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { useEffect } from "react";

function HowItWorksEdit() {
  const { id } = useParams();
  const { setFormData, formData, loading, error, handleSubmit } = usePOST();
  const { data } = useFETCH(`info-text/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`info-text`);
  };
  let dataOld = data?.data.data;
  console.log(dataOld);
  useEffect(() => {
    setFormData({
      info: id,
      en_info_value: dataOld?.info_value?.en,
      ar_info_value: dataOld?.info_value?.ar,
    });
  }, [dataOld]);

  return (
    <Container className="px-1">
      <Row
        justify="center"
        className="items-center border-2 border-Secondary rounded-xl py-5"
      >
        <div className="my-8">
          <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
          <JoditEditor
            value={formData?.ar_info_value}
            onChange={(e) => setFormData({ ...formData, ar_info_value: e })}
          />
        </div>
        <div className="my-8">
          <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
          <JoditEditor
            value={formData?.en_info_value}
            onChange={(e) => setFormData({ ...formData, en_info_value: e })}
          />
        </div>
        {loading ? <Loading /> : ""}
        <div className="text-red-500 font-semibold">{error}</div>
        <Col col={6}>
          <ButtonRed
            onClick={handleSubmitMain}
            name="Add"
            className="h-full px-4 py-3"
          />
        </Col>
      </Row>
      <Back />
    </Container>
  );
}
export default HowItWorksEdit;
