import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { AddImage, Back, ButtonRed, Input, Loading } from "../../components";
import { useEffect, useState } from "react";
import Select from "react-select";

const LaunchesEdit = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    setViewFile,
    handleSubmit,
    setFormData,
    viewFile,
    formData,
    loading,
    error,
  } = usePOST({ trending: 0 });
  const { data: dataCoins } = useFETCH(`coins?is_active=1`);
  const { data } = useFETCH(id === "add" ? "" : `coming-soon-items/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "coming-soon-items" : `coming-soon-items/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        en_name: dataOld?.name?.en,
        ar_name: dataOld?.name?.ar,
        coin_id: dataOld?.coin_id,
        price_start_from: dataOld?.price_start_from,
        trending: dataOld?.trending,
      });
    setSelectedCoins(
      CoinsOption?.filter((coin) => coin.value === +formData?.coin_id)[0]
    );
  }, [dataOld]);
  const CoinsOption = dataCoins?.data.data.data.map((e) => {
    return { value: e.id, label: e.code };
  });
  const [selectedCoins, setSelectedCoins] = useState();
  return (
    <div>
      <Row justify={"center"}>
        <Col md={9}>
          <Row
            justify="center"
            className="border-2 border-Secondary p-8 rounded-xl"
          >
            <Input
              name="ar_name"
              value={formData?.ar_name}
              onChange={handleChangeInput}
              title="الاسم بالعربي"
            />
            <Input
              name="en_name"
              value={formData?.en_name}
              onChange={handleChangeInput}
              title="الاسم بالإنكليزي"
              className="text-end"
            />
            <Input
              type="number"
              name="price_start_from"
              value={formData?.price_start_from}
              onChange={handleChangeInput}
              title="السعر يبدأ من"
            />
            <h1 className={`mb-2`}>العملة</h1>
            <Select
              options={CoinsOption}
              name="coin_id"
              value={selectedCoins}
              onChange={(selectedCoin) => {
                setSelectedCoins(selectedCoin);
                setFormData({ ...formData, coin_id: selectedCoin.value });
              }}
            />
            <Col>
              <AddImage
                name="image"
                newImage={viewFile}
                oldImage={dataOld?.image && fileUrl + dataOld?.image}
                onChange={handleChangeInput}
                clickDeleteImage={() => {
                  setViewFile("");
                  setFormData({ ...formData, images: "" });
                }}
              />
            </Col>
            <div className="flex items-center gap-3 my-4 text-lg font-semibold">
              <input
                type="checkbox"
                name="trending"
                checked={formData.trending}
                onChange={handleChangeInput}
                className="w-6 h-6"
              />
              <p>trending</p>
            </div>
            {loading ? <Loading /> : ""}
            <div className="text-red-500 font-semibold">{error}</div>
            <Col col={6}>
              <ButtonRed
                onClick={handleSubmitMain}
                name="Add"
                className="h-full px-4 py-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default LaunchesEdit;
