import { Col } from "../../Tools/Grid-system";
import { CardBox, Title } from "../../components";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import parse from "html-react-parser";

const TermsOfUse = () => {
  const { data } = useFETCH(`info-text/terms_and_conditions`);
  console.log(data);
  return (
    <div className="py-5">
      <Title title="شروطنا" />
      <Col lg={9} className="mx-auto">
        <CardBox deleted edit={`/terms-of-use/terms_and_conditions`}>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
            <div>
              {parse(
                data?.data.data.info_value?.ar
                  ? data?.data.data.info_value?.ar
                  : ""
              )}
            </div>
          </div>
          <div>
            <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
            <div>
              {parse(
                data?.data.data.info_value?.en
                  ? data?.data.data.info_value?.en
                  : ""
              )}
            </div>
          </div>
        </CardBox>
      </Col>
    </div>
  );
};

export default TermsOfUse;
