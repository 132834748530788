import { useState } from "react";
import { usePOST } from "../../Tools/APIs/useMyAPI";
const IsActive = ({ id, active, children, name }) => {
  const [sure, setSure] = useState(false);
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`coins/${id}?is_active=${active}`, "", "", true);
  };
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-sm:w-[320px] min-h-[160px]">
              <p className="font-semibold text-3xl text-center py-3 ">
                هل تريد جعل العملة {name} متاحة
              </p>
              <div className="flex items-end justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={handleClick}
                  >
                    نعم
                  </div>
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => setSure(false)}
                  >
                    تراجع
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div
          onClick={() => setSure(true)}
          className="w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center"
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default IsActive;
