import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineMail, MdWorkOutline } from "react-icons/md";
import { ButtonRed, CardBox, Loading, Title } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { useFETCH } from "../../Tools/APIs/useMyAPI";
import parse from "html-react-parser";
const ContactUs = () => {
  const { data, isLoading } = useFETCH(`contact-us`);
  const { data: datas } = useFETCH(`info-text/address`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Title title="تواصل معنا" />
      {isLoading ? <Loading /> : ""}
      <Row gap={5} justify="center">
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <FaPhone size={50} className="" color="#FEDADA" />
          <p className="font-semibold px-2 text-Gray text-xl">
            {dataAll?.phone_number}
          </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <MdOutlineMail size={60} className="" color="#9F7CC6" />
          <p className="font-semibold px-2 text-Gray text-xl">
            {dataAll?.email}
          </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <MdWorkOutline size={60} className="" color="#5e2aC6" />
          <p className="font-semibold px-2 text-Gray text-xl">
            {dataAll?.working_hours}
          </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <FaInstagram size={60} className="" color="#CB3878" />
          <p className="font-semibold px-2 text-Gray text-xl">
            {dataAll?.instagram}
          </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <FaFacebook size={60} className="" color="#3B5998" />
          <p className="font-semibold px-2 text-Gray text-xl">
            {dataAll?.facebook}
          </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <BsWhatsapp size={60} className="" color="#1DCD2A" />
          <p className="font-semibold px-2 text-Gray text-xl">
            {dataAll?.whatsapp}
          </p>
        </Col>
        <Col md={10}>
          <CardBox deleted edit={`/Contact-Us/address`}>
            <div>
              <h1 className="text-xl font-semibold mb-2">العنوان بالعربي</h1>
              <div>
                {parse(
                  datas?.data.data.info_value?.ar
                    ? datas?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">العنوان بالإنكليزي</h1>
              <div>
                {parse(
                  datas?.data.data.info_value?.en
                    ? datas?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
      </Row>
      <div className="fixed bottom-10 left-10">
        <ButtonRed link="/Contact-Us/edit" name="Edit" className="px-7 py-2" />
      </div>
    </div>
  );
};

export default ContactUs;
