import { BiMenu } from "react-icons/bi";
import Logo from "../../images/Logo.png";
import { useContextHook } from "../../Context/ContextOPen";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const navigate = useNavigate();
  const handleFormSubmit = () => {
    window.localStorage.clear();
    window.location.reload();
    navigate("/login");
  };
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-3xl text-center py-7">
                هل انت متأكد من تسجيل الخروج
              </p>
              <div className="flex items-center justify-center gap-3 m-5">
                <button
                  onClick={handleFormSubmit}
                  className="border px-10 border-Main text-Main bg-white hover:bg-Main hover:text-white font-semibold p-3 rounded-xl ml-5"
                >
                  نعم
                </button>
                <button
                  onClick={() => setSure(false)}
                  className="border px-10 border-Main text-Main bg-white hover:bg-Main hover:text-white font-semibold p-3 rounded-xl ml-5"
                >
                  لا
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="container mx-auto sticky top-0 z-40 bg-white flex items-center justify-between flex-wrap mb-5 pb-2 border-b-2 border-Main">
        <div>
          <BiMenu size={35} className="cursor-pointer" onClick={changeMenu} />
        </div>
        <div className="">
          <img src={Logo} alt="" className="w-24 pt-2" />
        </div>
        <button
          onClick={() => setSure(true)}
          className="bg-Main rounded-xl hover:bg-opacity-70 text-center px-7 py-3 font-semibold text-white cursor-pointer"
        >
          تسجيل خروج
        </button>
      </div>
    </>
  );
};

export default Navbar;
