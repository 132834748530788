import React from "react";
import {
  Add,
  ButtonRed,
  CardBox,
  Data,
  Loading,
  Title,
} from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import Pagination from "../../Tools/Pagination";

const Users = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `users?page=${filter.get("page") ? filter.get("page") : 1}`,
    "users"
  );
  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Row className="" justify={"between"}>
        <Col md={4}>
          <Title title="المستخدمين" />
        </Col>
      </Row>
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row className={"pt-5"}>
          {data?.data.data.data.map((e) => (
            <Col lg={4} md={6} className="mb-2">
              <CardBox
                deleteClick={() => deleteItem(e)}
                edit={`/users/${e.id}`}
              >
                <Data name="اسم المسخدم" text={e.name} />
                <Data name="الإيميل" text={e.email} />
                {/* <Row className="justify-center pt-3">
                    <Col col={6}>
                      <ButtonRed
                        className="py-2"
                        link={`/users/Orders-user/${e.id}`}
                        name="Orders"
                      />
                    </Col>
                  </Row> */}
              </CardBox>
            </Col>
          ))}
        </Row>
      </Pagination>
      <Add link="/users/add" />
    </div>
  );
};

export default Users;
