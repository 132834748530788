function Input({ title, name, value, type, onChange, className }) {
  return (
    <div className="my-3 px-1">
      <h1 className={`text-start mb-2 ${className}`}>{title}</h1>
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className={`w-full border-2 border-Main m-1 py-3 rounded-xl ${className}`}
      />
    </div>
  );
}
export default Input;
