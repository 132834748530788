import {
  AddUser,
  ChangePassword,
  ContactUs,
  EditHome,
  Home,
  Login,
  Message,
  UpdateContact,
  AboutUs,
  Users,
  Compounds,
  Areas,
  EditArea,
  EditCompounds,
  Design,
  Blogs,
  Careers,
  DesignEdit,
  EditBlogs,
  EditCareers,
  JobOpeningsEdit,
  OurPeopleEdit,
  OurBenefitsEdit,
  HowItWorks,
  TermsOfUse,
  Launches,
  LaunchesEdit,
  Code,
  SetPassword,
  ForgetPassword,
  AddAttached,
  Attached,
  PropertyType,
  AddPropertyType,
  Coins,
  Properties,
  AddProperties,
  HowItWorksEdit,
  OurProducts,
  AddOurProduct,
  TextInfo,
  ViewProperty,
} from "./pages/index";
import { Navbar, SideBar } from "./layout/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/Logo.png";
import { RequireAuth } from "./Tools/APIs/useMyAPI";
import { Container } from "./Tools/Grid-system";
import Founders from "./pages/Founders/Founders";
import AddFounders from "./pages/Founders/AddFounders";
const App = () => {
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="forget-password" element={<ForgetPassword />} />
              <Route path="verification-code" element={<Code />} />
              <Route path="set-password" element={<SetPassword />} />
              <Route element={<RequireAuth />}>
                <Route
                  path=""
                  element={
                    <h1 className="grid place-content-center h-[80vh]">
                      <img src={Logo} alt="" className="w-[250px]" />
                    </h1>
                  }
                />
                <Route
                  path="*"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      404 | no page found
                    </h1>
                  }
                />
                <Route
                  path="403"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      403 | FORBIDDEN
                    </h1>
                  }
                />
                <Route
                  path="500"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      500 | Internal Server Error
                    </h1>
                  }
                />
                <Route path="home">
                  <Route index element={<Home />} />
                  <Route path="add" element={<EditHome />} />
                </Route>
                <Route path="users">
                  <Route index element={<Users />} />
                  <Route path=":id" element={<AddUser />} />
                </Route>
                <Route path="Contact-Us">
                  <Route index element={<ContactUs />} />
                  <Route path="edit" element={<UpdateContact />} />
                  <Route path=":id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="message" element={<Message />} />
                <Route path="attached">
                  <Route index element={<Attached />} />
                  <Route path=":id" element={<AddAttached />} />
                </Route>
                <Route path="property-type">
                  <Route index element={<PropertyType />} />
                  <Route path=":id" element={<AddPropertyType />} />
                </Route>
                <Route path="properties">
                  <Route index element={<Properties />} />
                  <Route path=":id" element={<AddProperties />} />
                  <Route path="view/:id" element={<ViewProperty />} />
                </Route>
                <Route path="founders">
                  <Route
                    index
                    element={
                      <Founders name="المؤسسون" rout="founders" api="FOUNDER" />
                    }
                  />
                  <Route path=":id" element={<AddFounders api="FOUNDER" />} />
                </Route>
                <Route path="news">
                  <Route
                    index
                    element={<Founders name="الأخبار" rout="news" api="NEWS" />}
                  />
                  <Route path=":id" element={<AddFounders api="NEWS" />} />
                </Route>
                <Route path="Compounds">
                  <Route index element={<Compounds />} />
                  <Route path=":id" element={<EditCompounds />} />
                </Route>
                <Route path="area">
                  <Route index element={<Areas />} />
                  <Route path=":id" element={<EditArea />} />
                </Route>
                <Route path="coins">
                  <Route index element={<Coins />} />
                </Route>
                <Route path="launches">
                  <Route index element={<Launches />} />
                  <Route path=":id" element={<LaunchesEdit />} />
                  <Route path="description/:id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="design">
                  <Route index element={<Design />} />
                  <Route path="add" element={<DesignEdit />} />
                  <Route path=":id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="blogs">
                  <Route index element={<Blogs />} />
                  <Route path=":id" element={<EditBlogs />} />
                </Route>
                <Route path="careers">
                  <Route index element={<Careers />} />
                  <Route path="editCareers" element={<EditCareers />} />
                  <Route path="JobOpeningsEdit" element={<JobOpeningsEdit />} />
                  <Route path="OurPeopleEdit" element={<OurPeopleEdit />} />
                  <Route path="OurBenefitsEdit" element={<OurBenefitsEdit />} />
                </Route>
                <Route path="about-us">
                  <Route index element={<AboutUs id="about-us" />} />
                  <Route path=":id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="how-it-works">
                  <Route index element={<HowItWorks />} />
                  <Route path="buyer/:id" element={<HowItWorksEdit />} />
                  <Route path="seller/:id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="text-info">
                  <Route index element={<TextInfo />} />
                  <Route path="contact/:id" element={<HowItWorksEdit />} />
                  <Route
                    path="acceptable_use/:id"
                    element={<HowItWorksEdit />}
                  />
                  <Route
                    path="automated_queries/:id"
                    element={<HowItWorksEdit />}
                  />
                  <Route path="departments/:id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="terms-of-use">
                  <Route index element={<TermsOfUse />} />
                  <Route path=":id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="our_products">
                  <Route index element={<OurProducts />} />
                  <Route path="add" element={<AddOurProduct />} />
                  <Route path=":id" element={<HowItWorksEdit />} />
                </Route>
                <Route path="Change-Password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
