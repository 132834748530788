import { useFETCH } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { CardBox, Title } from "../../components";
import parse from "html-react-parser";
function TextInfo() {
  const { data } = useFETCH(`info-text/description_contact`);
  const { data: data1 } = useFETCH(`info-text/acceptable_use`);
  const { data: data2 } = useFETCH(`info-text/automated_queries`);
  const { data: data3 } = useFETCH(`info-text/description_departments`);

  return (
    <div>
      <Title title="نصوص المشروع" />
      <Row className="justify-center">
        <Col lg={9}>
          <CardBox deleted edit="/text-info/contact/description_contact">
            <h2 className="text-xl font-semibold text-red-600 text-center">
              وصف صفحة الاتصالات
            </h2>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  data?.data.data.info_value?.ar
                    ? data?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  data?.data.data.info_value?.en
                    ? data?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
        <Col lg={9}>
          <CardBox deleted edit="/text-info/acceptable_use/acceptable_use">
            <h2 className="text-xl font-semibold text-red-600 text-center">
              وصف الاستخدام المقبول
            </h2>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  data1?.data.data.info_value?.ar
                    ? data1?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  data1?.data.data.info_value?.en
                    ? data1?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
        <Col lg={9}>
          <CardBox
            deleted
            edit="/text-info/automated_queries/automated_queries"
          >
            <h2 className="text-xl font-semibold text-red-600 text-center">
              وصف الاستفسار الالي
            </h2>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  data2?.data.data.info_value?.ar
                    ? data2?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  data2?.data.data.info_value?.en
                    ? data2?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
        <Col lg={9}>
          <CardBox
            deleted
            edit="/text-info/departments/description_departments"
          >
            <h2 className="text-xl font-semibold text-red-600 text-center">
              وصف الاقسام
            </h2>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالعربي</h1>
              <div>
                {parse(
                  data3?.data.data.info_value?.ar
                    ? data3?.data.data.info_value?.ar
                    : ""
                )}
              </div>
            </div>
            <div>
              <h1 className="text-xl font-semibold mb-2">النص بالإنكليزي</h1>
              <div>
                {parse(
                  data3?.data.data.info_value?.en
                    ? data3?.data.data.info_value?.en
                    : ""
                )}
              </div>
            </div>
          </CardBox>
        </Col>
      </Row>
    </div>
  );
}
export default TextInfo;
// "description_contact": "test test ennnn",
// "acceptable_use": "test test ennnn",
// "automated_queries": "test test ennnn",
// "description_departments": "test test ennnn",
