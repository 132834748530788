import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { IsActive, CoinFilter, Loading, Title, Data } from "../../components";
import { useFETCH, useFilter } from "../../Tools/APIs/useMyAPI";
import { MdBlock } from "react-icons/md";
import { BiShow } from "react-icons/bi";

function Coins() {
  const { filter } = useFilter({});
  const { data, isLoading } = useFETCH(
    `coins?is_active=1&page=${filter.get("page") ? filter.get("page") : 1}${
      filter.get("is_active") ? "&is_active=" + filter.get("is_active") : ""
    }${filter.get("search") ? "&search=" + filter.get("search") : ""}`,
    "coins"
  );
  return (
    <div>
      <Title title="العملات" />
      <CoinFilter />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row>
          {data?.data.data.data.map((e) => (
            <Col lg={4} xs={6} key={e.id}>
              <div
                className={`border-2 border-Main rounded-2xl p-3 w-full h-full`}
              >
                {e.is_active === 0 ? (
                  <IsActive id={e.id} active="1">
                    <BiShow size={30} className=" text-blue-700" />
                  </IsActive>
                ) : (
                  <IsActive id={e.id} active="0" name="غير">
                    <MdBlock size={30} className=" text-red-700" />
                  </IsActive>
                )}
                <Data name="اسم البلد" text={e.country} />
                <Data name="العملة" text={e.code} />
              </div>
            </Col>
          ))}
        </Row>
      </Pagination>
    </div>
  );
}
export default Coins;
