import { usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Row } from "../../Tools/Grid-system";
import { AddImage, Back, ButtonRed, Loading } from "../../components";

const DesignEdit = () => {
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewFile,
    viewFile,
    formData,
    loading,
    error,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`slider-image?type=DESIGN`);
  };
  return (
    <div>
      <Row justify={"center"}>
        <Col md={7} className="">
          <AddImage
            name="image"
            newImage={viewFile}
            onChange={handleChangeInput}
            title="إضف صورة"
            clickDeleteImage={() => {
              setViewFile("");
              setFormData({ ...formData, images: "" });
            }}
          />
          {loading ? <Loading /> : ""}
          <div className="text-red-500 font-semibold">{error}</div>
          <ButtonRed
            name="Add"
            className="px-4 py-3"
            onClick={handleSubmitMain}
          />
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default DesignEdit;
